
import { defineComponent } from "vue";
import TabPanel from "primevue/tabpanel";
import TabView from "primevue/tabview";
import Button from "primevue/button";
import { mapGetters, mapActions } from "vuex";
import Utils from "@/utility/utils";
import { cloneDeep } from "lodash";
import SalesOpportunityForm from "../Sales/SalesOpportunityForm.vue";

export default defineComponent({
  components: {
    TabPanel,
    TabView,
    Button,
    SalesOpportunityForm,
  },
  computed: {
    ...mapGetters({
      getActiveCustomerOpenSalesOpps:
        "customerInquiry/getActiveCustomerOpenSalesOpps",
      getActiveTab: "customerInquiry/getActiveTab",
      getActiveIndexCustomer: "customerInquiry/getActiveIndexCustomer",
    }),
  },
  data() {
    return {
      opportunityChanges: {} as any,
    };
  },
  methods: {
    ...mapActions({
      changeActiveMainTab: "customerInquiry/changeActiveTab",
      removeSalesOpp: "customerInquiry/removeSalesOpp",
      setActiveTab: "customerInquiry/setActiveTab",
      updateOpenedSalesOppCustomerInquiry:
        "customerInquiry/updateOpenedSalesOppCustomerInquiry",
    }),
    formatTabId(id: string) {
      return "#" + id.replace("New Opp-", "");
    },
    handleMainTabChange(e: any) {
      this.changeActiveMainTab(e.index !== undefined ? e.index : 0);
    },
    handleRemoveSaleOpp(id: string) {
      this.removeSalesOpp(id);
      if (this.getActiveCustomerOpenSalesOpps.length === 0) {
        this.setActiveTab(0);
        this.$router.push("/customers/sales/opportunities");
      } else {
        this.setActiveTab(1);
      }
    },
    handleUpdateSalesOpportunity(data: any, index: number) {
      this.updateOpenedSalesOppCustomerInquiry({ payload: data, index: index });
    },
    handleOpportunityChanges(newOpp: any, oldOpp: any) {
      this.opportunityChanges[newOpp.id as string] = Utils.compareSalesOpp(
        newOpp,
        oldOpp,
      );
    },
  },
});
